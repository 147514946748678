import { bech32 } from 'bech32'
import { isAddress as isValidEthAddress } from 'ethers'
import * as bitcoin from 'bitcoinjs-lib'
import { decodeAddress, encodeAddress } from '@polkadot/keyring'
import { hexToU8a, isHex } from '@polkadot/util'
import { BigNumber } from 'bignumber.js'
import { captureException } from '@sentry/browser'
import { PublicKey } from '@solana/web3.js'

export const nativeAsset = 'MAYA.CACAO'
export const STABLE_POOL_ASSET = 'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7'

export const tokenIcons = {
  Native: '/tokens/chains.png',
  All: '/tokens/chains.png',
  MAYA: '/tokens/maya.png',
  'MAYA.CACAO': '/tokens/cacao.png',
  'MAYA.MAYA': '/tokens/maya.png',
  THOR: '/tokens/rune.png',
  'THOR.RUNE': '/tokens/rune.png',
  BTC: '/tokens/btc.png',
  'BTC.BTC': '/tokens/btc.png',
  DASH: '/tokens/dash.png',
  'DASH.DASH': '/tokens/dash.png',
  ETH: '/tokens/eth.png',
  'ETH.ETH': '/tokens/eth.png',
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': '/tokens/usdc.png',
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': '/tokens/usdt.png',
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': '/tokens/wsteth.png',
  'ETH.USDC': '/tokens/usdc.png',
  ARB: '/tokens/arbitrum.png',
  'ARB.ETH': '/tokens/eth.png',
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': '/tokens/arbitrum.png',
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': '/tokens/dai.png',
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': '/tokens/gld.png',
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': '/tokens/gmx.png',
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': '/tokens/gns.png',
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': '/tokens/leo.png',
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': '/tokens/link.png',
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': '/tokens/pepe.webp',
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': '/tokens/sushi.png',
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': '/tokens/tgt.png',
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': '/tokens/uni.png',
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': '/tokens/usdc.png',
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': '/tokens/usdt.png',
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': '/tokens/wbtc.png',
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': '/tokens/wsteth.png',
  LTC: '/tokens/ltc.png',
  'LTC.LTC': '/tokens/ltc.png',
  BCH: '/tokens/bch.png',
  'BCH.BCH': '/tokens/bch.png',
  BNB: '/tokens/bnb.svg',
  'BNB.BNB': '/tokens/bnb.svg',
  DOGE: '/tokens/doge.png',
  'DOGE.DOGE': '/tokens/doge.png',
  KUJI: '/tokens/kuji.png',
  'KUJI.KUJI': '/tokens/kuji.png',
  'KUJI.USK': '/tokens/usk.png',
  ATOM: '/tokens/atom.svg',
  'GAIA.ATOM': '/tokens/atom.svg',
  AVAX: '/tokens/avax.svg',
  'AVAX.AVAX': '/tokens/avax.svg',
  '.ASYMM': '/tokens/asymm.png',
  'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A': '/tokens/chainflip.svg',
  DOT: '/tokens/polkadot.svg',
  'DOT.DOT': '/tokens/polkadot.svg',

  SOLANA: '/tokens/solana.png',
  'SOLANA.SOL': '/tokens/solana.png',

  'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': '/tokens/usdc.png',
  'SOLANA.USDC': '/tokens/usdc.png',

  'SOLANA.USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB': '/tokens/usdt.png',
  'SOLANA.USDT': '/tokens/usdt.png',

  'SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H': '/tokens/wewe.webp',
  'SOLANA.WEWE': '/tokens/wewe.webp',

  'SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump': '/tokens/dmaga.webp',
  'SOLANA.DMAGA': '/tokens/dmaga.webp',

  'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd': '/tokens/zyn.jpg',
  'SOLANA.ZYN': '/tokens/zyn.jpg',

  'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb': '/tokens/zyn.jpg',
  'ETH.ZYN': '/tokens/zyn.jpg',

  'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7': '/tokens/trump.png',
  'ETH.TRUMP': '/tokens/trump.png',

  'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb': '/tokens/tren.jpeg',
  'SOLANA.TREN': '/tokens/tren.jpeg',

  'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa': '/tokens/apu.jpeg',
  'ETH.APU': '/tokens/apu.jpeg',

  'SOLANA.BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ': '/tokens/barron.jpeg',
  'SOLANA.BARRON': '/tokens/barron.jpeg',

  'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061': '/tokens/ena.png',
  'ETH.ENA': '/tokens/ena.png',

  'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df': '/tokens/alcx.png',
  'ETH.ALCX': '/tokens/alcx.png',

  'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': '/tokens/yfi.png',
  'ETH.YFI': '/tokens/yfi.png',

  'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': '/tokens/aave.jpeg',
  'ETH.AAVE': '/tokens/aave.jpeg',

  'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a': '/tokens/mog.png',
  'ETH.MOG': '/tokens/mog.png',

  'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70': '/tokens/jesus.png',
  'ETH.JESUS': '/tokens/jesus.png',

  'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC': '/tokens/ldr.png',
  'SOLANA.LDR': '/tokens/ldr.png',

  'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor': '/tokens/swag.jpeg',
  'SOLANA.SWAG': '/tokens/swag.jpeg',

  'SOLANA.RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx': '/tokens/retardio.png',
  'SOLANA.RETARDIO': '/tokens/retardio.png',

  'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR': '/tokens/kenidy.jpeg',
  'SOLANA.KENIDY': '/tokens/kenidy.jpeg',

  'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3': '/tokens/slerf.png',
  'SOLANA.SLERF': '/tokens/slerf.png',

  'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82': '/tokens/bome.png',
  'SOLANA.BOME': '/tokens/bome.png',

  'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm': '/tokens/wif.png',
  'SOLANA.WIF': '/tokens/wif.png',

  'ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933': '/tokens/pepe.webp',
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': '/tokens/pepe.webp',
  'ETH.PEPE': '/tokens/pepe.webp',

  'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263': '/tokens/bonk.png',
  'SOLANA.BONK': '/tokens/bonk.png',

  'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o': '/tokens/boden.png',
  'SOLANA.BODEN': '/tokens/boden.png',
}
export function getTokenIcon(asset) {
  const capitalizedKeysTokenIcons = {}
  for (const key in tokenIcons) {
    if (Object.hasOwn(tokenIcons, key)) {
      const upperKey = key.toUpperCase()
      capitalizedKeysTokenIcons[upperKey] = tokenIcons[key]
    }
  }
  const icon = capitalizedKeysTokenIcons[asset]
  return icon
}

export const dummyDestinations: { [key: string]: string } = {
  BTC: 'bc1qvqxs558vgquwkchw64erd5hfpxfj5kmn7lyklz',
  ETH: '0x7316da75796F74E83B71c72ed6F138dd3B6B4957',
  ARB: '0x7316da75796F74E83B71c72ed6F138dd3B6B4957',
  THOR: 'thor13x0f2r0jltfplmxe40cc67hhca27np34e495yr',
  MAYA: 'maya13x0f2r0jltfplmxe40cc67hhca27np34ezmcjn',
  DASH: 'XgYRN3TLoYaodB5Y6AMZuYhjv8fKKG5tgh',
  KUJI: 'kujira1x8z69wuczjk42l22c3u6qzzd5vdeqdyhwkv84v',
}

export const ASSET_DECIMALS: { [key: string]: number } = {
  'THOR.RUNE': 8,
  'MAYA.CACAO': 10,
  'ETH.ETH': 18,
  'KUJI.KUJI': 6,
  'KUJI.USK': 8,
  ukuji: 6,
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 6,
  'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A': 18,
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 18,
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 6,
  'ETH/USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 6,
  'ETH/USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 6,
  'ETH/WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 18,
  'BTC.BTC': 8,
  'DASH.DASH': 8,
  'DASH/DASH': 8,
  'ETH/ETH': 8,
  'BTC/BTC': 8,
  'KUJI/KUJI': 8,
  'KUJI/USK': 8,
  'THOR/RUNE': 8,
  'DOT.DOT': 10,
  'SOLANA.SOL': 9,
  'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': 6,
  'SOLANA.USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB': 6,
  'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd': 8,
  'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7': 9,
  'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb': 18,
  'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3': 9,
  'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263': 5,
  'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm': 6,
  'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82': 6,
  'ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933': 18,
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': 8,
  'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o': 9,
  'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR': 9,
  'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb': 9,
  'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa': 18,
  'SOLANA.BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ': 6,
  'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061': 18,
  'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df': 18,
  'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': 18,
  'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': 18,
  'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a': 18,
  'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70': 18,
  'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC': 6,
  'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor': 2,
  'SOLANA.RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx': 6,
  'SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H': 8,
  'SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump': 6,
  'ARB.ETH': 18,
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 18,
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 18,
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 18,
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 18,
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 18,
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 3,
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 18,
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 18,
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 18,
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 18,
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 18,
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 6,
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 6,
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 18,
  'ARB/ETH': 8,
  'ARB/ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 8,
  'ARB/DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 8,
  'ARB/GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 8,
  'ARB/GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 8,
  'ARB/GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 8,
  'ARB/LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 8,
  'ARB/LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 8,
  'ARB/PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 8,
  'ARB/SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 8,
  'ARB/TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 8,
  'ARB/UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 8,
  'ARB/USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 8,
  'ARB/USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 8,
  'ARB/WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB/WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 8,
}

export function getAssetDecimals(asset: string): number {
  if (!asset) throw new Error('Asset is required to retrieve decimals')
  const decimals = ASSET_DECIMALS[asset]
  if (!decimals) throw new Error('No decimals defined for asset: ' + asset)
  return decimals
}

export function createQueryString(baseURL: string, params: { [key: string]: string }) {
  // Map each key-value pair to a 'key=value' format, ensuring values are URI-encoded
  const queryString = Object.entries(params)
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')

  // Construct the full URL
  return `${baseURL}?${queryString}`
}

export function assetIntegerToMayaInteger(integer: string, asset: string): string {
  const mayaDecimals = asset === 'MAYA.CACAO' ? 10 : 8
  return (parseInt(integer) / 10 ** (getAssetDecimals(asset) - mayaDecimals)).toFixed(0)
}

export function mayaIntegerToAssetInteger(integer: string, asset: string) {
  const mayaDecimals = asset === 'MAYA.CACAO' ? 10 : 8

  // Convert integer to BigInt
  const bigIntInteger = BigInt(integer)

  // Determine the scaling factor based on the relative size of mayaDecimals and ASSET_DECIMALS[asset]
  const decimalDifference = getAssetDecimals(asset) - mayaDecimals

  let result
  if (decimalDifference >= 0) {
    // If ASSET_DECIMALS[asset] is greater than or equal to mayaDecimals, scale up
    const powerOfTen = BigInt(10) ** BigInt(decimalDifference)
    result = bigIntInteger * powerOfTen
  } else {
    // If mayaDecimals is greater, scale down. Use division and ensure the result is a BigInt.
    const powerOfTen = BigInt(10) ** BigInt(-decimalDifference)
    result = bigIntInteger / powerOfTen
  }

  // Convert the result to a string to avoid scientific notation
  return result.toString()
}

export function bigIntToDecimalString(bigIntValue: BigInt, decimals: number): string {
  const stringValue = bigIntValue.toString()
  return IntStringToDecimalString(stringValue, decimals)
}

export function mayaIntegerToNumber(mayaInteger: string, asset: string): number {
  const decimals = getAssetDecimals(asset)
  return parseInt(mayaInteger) / 10 ** decimals
}

export function IntStringToDecimalString(intValue: string, decimals: number): string {
  const stringValue = intValue.toString()
  const integerPart = stringValue.length > decimals ? stringValue.slice(0, -decimals) : '0'
  let fractionalPart =
    stringValue.length > decimals
      ? stringValue.slice(-decimals)
      : stringValue.padStart(decimals, '0')

  // Optionally: Remove trailing zeros from the fractional part
  fractionalPart = fractionalPart.replace(/0+$/, '')

  // Ensure there's a fractional part to display, otherwise return the integer part only
  return fractionalPart.length > 0 ? `${integerPart}.${fractionalPart}` : integerPart
}

export const DISPLAY_DECIMALS: { [key: string]: number } = {
  'MAYA.CACAO': 3,
  'THOR.RUNE': 3,
  'ETH.ETH': 4,
  'ARB.ETH': 4,
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 2,
  'ETH.USDC': 2,
  'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A': 4,
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 4,
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 4,
  'BTC.BTC': 8,
  'DASH.DASH': 4,
  'ETH/ETH': 4,
  'BTC/BTC': 5,
  USD: 2,
  'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v': 4,
  'SOLANA.USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB': 4,
  'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd': 4,
  'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7': 4,
  'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb': 4,
  'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o': 4,
  'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR': 4,
  'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb': 4,
  'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061': 4,
  'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df': 4,
  'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': 4,
  'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': 4,
  'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a': 4,
  'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70': 4,
  'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC': 4,
  'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor': 2,
  'SOLANA.RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx': 4,
  'SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H': 4,
  'SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump': 4,
  'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa': 4,
  'SOLANA.BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ': 4,
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': 4,
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 4,
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4,
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 4,
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 4,
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 4,
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 4,
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 4,
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 4,
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 4,
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 4,
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 4,
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 2,
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 2,
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 4,
  'ARB/ETH': 4,
  'ARB/ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 4,
  'ARB/DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4,
  'ARB/GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 4,
  'ARB/GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 4,
  'ARB/GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 4,
  'ARB/LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 4,
  'ARB/LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 4,
  'ARB/PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 4,
  'ARB/SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 4,
  'ARB/TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 4,
  'ARB/UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 4,
  'ARB/USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 4,
  'ARB/USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 4,
  'ARB/WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 4,
  'ARB/WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 4,
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

export type Balance = { asset: string; balance: number }
export type Balances = Balance[]
export function ensureMayaCacaoDisplayed(chain: string, balances: Balances) {
  if (chain === 'MAYA' && !('MAYA' in balances && !balances.MAYA) && balances.length < 1) {
    return [{ asset: 'MAYA.CACAO', balance: 0 }]
  }
  return balances
}

export function getAssetChain(asset: string) {
  if (!asset) return ''
  if (asset.includes('/')) {
    return 'MAYA'
  }
  if (asset.startsWith('DOT')) {
    return 'POLKADOT'
  }
  if (asset.startsWith('SOL')) {
    return 'SOLANA'
  }
  return asset.split('.')[0]
}

export function getAssetName(asset) {
  if (!asset) return ''
  if (asset.includes('/')) {
    return 's' + asset.split('/')[1].split('-')[0]
  }
  return asset.split('.')[1].split('-')[0].toUpperCase()
}

export function formatDatetime(d) {
  d = new Date(d)
  if (d.getTime() === 0) return '-'
  const pad = (s) => ('0' + s).slice(-2)
  return [
    d.getFullYear() + '-',
    pad(d.getMonth() + 1) + '-',
    pad(d.getDate()) + ' ',
    pad(d.getHours()) + ':',
    pad(d.getMinutes()),
  ].join('')
}

export function formatAddress(a) {
  if (!a) return '-'
  return a.slice(0, 6) + '…' + a.slice(-5)
}

export function formatWalletAddress(a) {
  if (!a) return '-'
  return a
}

export function decimalToIntegerString(decimal: string, decimals: number): string {
  BigNumber.config({ EXPONENTIAL_AT: 200 })
  const bigDecimals = new BigNumber(10).pow(decimals)
  const int = new BigNumber(decimal).times(bigDecimals) // TODO throw error if user input has more decimal places than asset

  const resultIntStr = int.toFixed(0)

  return resultIntStr === 'NaN' ? '0' : resultIntStr
}

export function decimalToDisplayStr(decimal: string | number, asset: string): string {
  if (typeof decimal === 'string') {
    decimal = parseInt(decimal)
  }
  return formatNumber(decimal, DISPLAY_DECIMALS[asset])
}

export function integerToDisplayStr(integer: string | number, asset: string): string {
  if (typeof integer === 'string') {
    integer = parseInt(integer)
  }
  const decimal = integer / 10 ** getAssetDecimals(asset)

  return formatNumber(decimal, DISPLAY_DECIMALS[asset])
}
export const integerToDecimalStr = (integer: string, asset: string): string => {
  const decimals = getAssetDecimals(asset)
  return integer.length === decimals
    ? '0.' + integer
    : integer.slice(0, decimals) + '.' + integer.slice(decimals)
}

export function formatNumber(number, decimals = 2, size = 8) {
  if (typeof number === 'string') {
    number = parseInt(number) / 10 ** decimals
  }
  if (typeof number === 'bigint') {
    number = parseInt(number.toString()) / 10 ** size
  }
  number = parseFloat(number)
  if (isNaN(number)) number = 0
  const factor = Math.pow(10, decimals)
  number = Math.floor(number * factor) / factor
  let suffix = ''
  if (number > 1000000) {
    number = number / 1000000
    suffix = 'M'
  } else if (number > 100000) {
    number = number / 1000
    suffix = 'K'
  }
  return (
    new Intl.NumberFormat('en-US', {
      useGrouping: 'always',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number) + suffix
  )
}
export const fetchAndSortPools = async () => {
  let poolsResponse = await fetchPools()

  for (const pool of [...poolsResponse]) {
    if (pool.asset === nativeAsset) continue
    poolsResponse = poolsResponse.concat([
      {
        ...pool,
        asset: pool.asset.replace('.', '/'),
      },
    ])
  }

  // Sort pools so CACAO is 1st and then by order, then the rest of the assets
  const order = ['BTC.BTC', 'ETH.ETH', 'KUJI.KUJI', 'THOR.RUNE', 'DASH.DASH']
  poolsResponse = poolsResponse.sort((a, b) => {
    if (a.asset === nativeAsset) return -1
    if (b.asset === nativeAsset) return 1
    if (!order.includes(a.asset)) return 1
    return order.indexOf(a.asset) - order.indexOf(b.asset)
  })
  return poolsResponse
}

export const isValidNetworkAddress = (address, chain) => {
  const networkValidations = {
    MAYA: isValidMayaAddress,
    KUJI: isValidKujiraAddress,
    THOR: isValidThorChainAddress,
    BTC: isValidBitcoinAddress,
    DASH: isValidDashAddress,
    ETH: isValidEthAddress,
    ARB: isValidEthAddress,
    DOT: isValidDotAddress,
    SOLANA: isValidSolanaAddress,
  }

  // Validate for a specified chain
  let isValidForChain = false
  if (chain && networkValidations[chain]) {
    isValidForChain = networkValidations[chain](address)
  } else {
    captureException(`Validation function for chain '${chain}' not found.`)
  }

  // Check if the address is valid for any chain
  const isValidForAnyChain = Object.values(networkValidations).some((validation) =>
    validation(address),
  )
  return { isValidForChain, isValidForAnyChain }
}

export function formatAddressLink(address: string, asset = '') {
  const chain = getAssetChain(asset)
  switch (chain) {
    case 'MAYA':
      return 'https://www.mayascan.org/address/' + address
    case 'THOR':
      return 'https://thorchain.net/address/' + address
    case 'ETH':
      return 'https://etherscan.io/address/' + address
    case 'ARB':
      return 'https://arbiscan.io/address/' + address
    case 'BTC':
      return 'https://mempool.space/address/' + address
    case 'DASH':
      return 'https://insight.dash.org/insight/address/' + address
    case 'KUJI':
      return 'https://finder.kujira.network/kaiyo-1/address/' + address
    case 'POLKADOT':
      return 'https://polkadot.subscan.io/account/' + address
    case 'SOLANA':
      return 'https://solscan.io/account/' + address
    default:
      return '#' + address
  }
}

export function formatExplorerLink(hash: string, asset = '') {
  const chain = getAssetChain(asset)
  switch (chain) {
    case 'MAYA':
      return 'https://www.mayascan.org/tx/' + hash
    case 'THOR':
      return 'https://thorchain.net/tx/' + hash
    case 'ETH':
      return 'https://etherscan.io/tx/' + hash
    case 'ARB':
      return 'https://arbiscan.io/tx/' + hash
    case 'BTC':
      return 'https://mempool.space/tx/' + hash
    case 'DASH':
      return 'https://insight.dash.org/insight/tx/' + hash
    case 'KUJI':
      return 'https://finder.kujira.network/kaiyo-1/tx/' + hash
    case 'POLKADOT':
      return 'https://polkadot.subscan.io/extrinsic/' + hash
    case 'SOLANA':
      return 'https://solscan.io/tx/' + hash
    default:
      return '#' + hash
  }
}

export async function apiRequest(path) {
  const fetchURL = 'https://node.eldorado.market/midgard/v2' + path

  const responseJson = await (await fetch(fetchURL)).json()

  return responseJson
}
export async function nodeRequest(path) {
  return await (await fetch('https://node.eldorado.market/mayanode/mayachain' + path)).json()
}

export function getSwapOutput(a, pool, toRune) {
  const X = parseFloat(toRune ? pool.assetDepth : pool.runeDepth) / 1e8
  const Y = parseFloat(toRune ? pool.runeDepth : pool.assetDepth) / 1e8
  return (a * X * Y) / (a + X) ** 2
}

export function getDoubleSwapOutput(a, pool1, pool2) {
  return getSwapOutput(getSwapOutput(a, pool1, true), pool2, false)
}

export function getAnySwapOutput(a, pool1, pool2) {
  if (pool1.asset === nativeAsset) {
    return getSwapOutput(a, pool2, false)
  } else if (pool2.asset === nativeAsset) {
    return getSwapOutput(a, pool1, true)
  } else {
    return getDoubleSwapOutput(a, pool1, pool2)
  }
}

export async function fetchPools() {
  const apiPools = await apiRequest('/pools')

  let pools = await nodeRequest('/pools')

  pools = pools.filter((p) => p.status === 'Available' || p.status === 'Staged')
  pools = pools.map((p) => ({
    asset: p.asset,
    balanceAsset: parseInt(p.balance_asset) / 1e8,
    balanceNative: parseInt(p.balance_cacao) / 1e10,
    units: parseInt(p.pool_units),
  }))

  pools.sort((a, b) => b.balanceNative - a.balanceNative)
  const usd = pools.find((p) => p.asset === STABLE_POOL_ASSET)
  const nativePrice = usd.balanceAsset / usd.balanceNative
  pools = pools.map((p) => {
    const apiPool = apiPools.find((p2) => p2.asset === p.asset)
    return {
      ...p,
      tvl: p.balanceNative * 2 * nativePrice,
      price: (p.balanceNative * nativePrice) / p.balanceAsset,
      volume: (parseInt(apiPool.volume24h) / 1e10) * nativePrice,
      apr: parseFloat(apiPool.poolAPY),
    }
  })
  pools = pools.concat({ asset: nativeAsset, price: nativePrice })

  return pools
}

export async function walletSwap({
  wallets,
  destination,
  amount,
  assetIn,
  assetOut,
  minAmountOut,
}) {
  const start = Date.now()
  const chainIn = getAssetChain(assetIn)
  // const chainOut = getAssetChain(assetOut)
  const walletIn = toRaw(wallets.find((w) => w.chain === chainIn))
  if (!walletIn) throw new Error('No wallet connected for the from asset')
  if (!destination) throw new Error('No recipient address')
  const inAmount = (parseFloat(amount) * 1e8).toFixed(0)
  const outAmount = (minAmountOut * 1e8).toFixed(0)

  const originalAsset = assetOut
  if (assetOut.includes('-')) {
    assetOut = assetOut.split('-')[0]
  }

  const txHash = await walletIn.deposit({
    amount,
    memo: `=:${assetOut}:${destination}::ELD:45`, // TODO include min amount out once it accounts for fees
    asset: assetIn, // On ETH, other tokens can be sent than the native currency
  })

  return {
    txHash,
    walletInAddress: walletIn.address,
    assetIn,
    // assetOut,
    originalAsset,
    inAmount,
    outAmount,
    start,
  }
  // Router.push(
  //   `/progress?hash=${txHash}&from=${walletIn.address}&in=${assetIn}&out=${originalAsset}&ina=${inAmount}&outa=${outAmount}&start=${start}`,
  // )
}

export async function walletAddSym({ wallets, amountIn, amountCacao, assetIn }) {
  const start = Date.now()
  const chainIn = getAssetChain(assetIn)
  const walletIn = toRaw(wallets.find((w) => w.chain === chainIn))
  const walletOut = toRaw(wallets.find((w) => w.chain === getAssetChain(nativeAsset)))
  if (!walletIn) throw new Error('No wallet connected for the from asset')
  if (!walletOut) throw new Error('No wallet connected for the to asset')
  const inAmount = (parseFloat(amountIn) * 1e8).toFixed(0)
  const memoIn = `+:${assetIn}:${walletOut.address}`
  const txHashIn = await walletIn.deposit({
    amount: amountIn,
    memo: memoIn, // TODO affiliate for fees
    asset: assetIn, // On ETH, other tokens can be sent than the native currency
  })
  const cacaoAmount = (parseFloat(amountCacao) * 1e8).toFixed(0)
  const memoCacao = `+:${assetIn}:${walletIn.address}`
  await walletOut.deposit({
    amount: amountCacao,
    memo: memoCacao, // TODO affiliate for fees
    asset: nativeAsset, // On ETH, other tokens can be sent than the native currency
  })
  // Router.push(
  //   `/progress?hash=${txHashIn}&from=${walletIn.address}&in=${assetIn}&out=${nativeAsset}&ina=${inAmount}&outa=${cacaoAmount}&start=${start}`,
  // )
  return {
    hash: txHashIn,
    from: walletIn.address,
    assetIn,
    out: nativeAsset,
    ina: inAmount,
    outa: cacaoAmount,
    start,
  }
}

export function tierPercent(tier) {
  return tier === 3 ? '4.5' : tier === 2 ? '1.5' : '0.5'
}

export async function walletAddAsym({ wallets, amount, asset }) {
  const start = Date.now()
  const chainIn = getAssetChain(asset)
  const walletIn = toRaw(wallets.find((w) => w.chain === chainIn))
  if (!walletIn) throw new Error('No wallet connected for the from asset')
  const inAmount = (parseFloat(amount) * 1e8).toFixed(0)
  const memo = `+:${asset}`
  const txHash = await walletIn.deposit({
    amount,
    memo, // TODO affiliate for fees
    asset, // On ETH, other tokens can be sent than the native currency
  })

  return { txHash, walletInAddress: walletIn.address, assetIn: asset, inAmount, start }
}

export async function walletWithdraw({
  wallets,
  basisPoints,
  asset,
  assetAmount,
  cacaoAmount,
  symmPosition,
  assetOut,
}) {
  const start = Date.now()
  const chainIn = getAssetChain(asset)
  const walletAsset = toRaw(wallets.find((w) => w.chain === chainIn))
  const walletNative = toRaw(wallets.find((w) => w.chain === getAssetChain(nativeAsset)))
  if (!walletAsset) throw new Error('No wallet connected for the from asset')
  if (!walletNative) throw new Error('No wallet connected for MAYA transactions')

  const basis = parseFloat(basisPoints * 10000).toFixed(0)
  if (isNaN(parseInt(basis)) || parseInt(basis) < 0 || parseInt(basis) > 10000)
    throw new Error(`Invalid basis points: ${basisPoints}, must be 0..10000.`)

  // amount changes whether maya or not
  // use cacao amount variable
  let amount = '0'
  const chain = getAssetChain(asset)
  // Caution Dust Limits: BTC,BCH,LTC chains 10k sats; DOGE 1m Sats; ETH 0 wei; THOR 0 RUNE.
  // To be explicit, we set the amount to 0 for the following chains:
  if (chain === 'ETH') amount = '0.000001'
  if (chain === 'ARB') {
    amount = '0.000001'
  }
  // if (chain === 'THOR') amount = '0'
  if (chain === 'DASH') amount = '0.0001'
  if (chain === 'LTC') amount = '0.0001'
  if (chain === 'KUJI') amount = '0.0001'
  if (chain === 'BTC') amount = '0.0001'
  if (chain === 'THOR') amount = '0.021' // According to maya docs THOR can handle 0 Rune, but our calculate Run Fee functions throws an error if it's > 0.02

  let assetOutMemo = ''
  if (symmPosition && assetOut.length > 0) assetOutMemo = `:${assetOut}`

  // Memo format:
  // WD:POOL:BASISPOINTS:ASSET
  const memo = `-:${asset}:${basis}${assetOutMemo}`

  let assetToDeposit = asset
  // If we have a asymm position we need to send a small amount of the gas asset on the asset chain
  if (chain === 'ARB') assetToDeposit = 'ARB.ETH'
  if (chain === 'ETH') assetToDeposit = 'ETH.ETH'
  if (chain === 'KUJI') assetToDeposit = 'KUJI.KUJI'

  // If we have a symm position we need to send a small amount of native maya chain gas (cacao)
  if (symmPosition) assetToDeposit = nativeAsset

  const wallet = assetToDeposit === nativeAsset ? walletNative : walletAsset
  const txHash = await wallet.deposit({
    amount,
    memo,
    asset: assetToDeposit,
  })

  return {
    hash: txHash,
    from: walletAsset.address,
    inAsset: asset,
    out: assetToDeposit,
    ina: (parseFloat(assetAmount) * 1e8).toFixed(0),
    outa: (parseFloat(cacaoAmount) * 1e8).toFixed(0),
    start,
  }
}

// export async function walletAddSavers({ wallets, amount, asset }) {
//   const start = Date.now()
//   const chainIn = getAssetChain(asset)
//   const walletIn = toRaw(wallets.find((w) => w.chain === chainIn))
//   if (!walletIn) throw new Error('No wallet connected for the from asset')
//   const inAmount = (parseFloat(amount) * 1e8).toFixed(0)
//   const memo = `+:${asset.replace('.', '/')}::ELD:45`
//   const txHash = await walletIn.deposit({
//     amount,
//     memo, // TODO affiliate for fees
//     asset, // On ETH, other tokens can be sent than the native currency
//   })
//   Router.push(
//     `/progress?hash=${txHash}&from=${walletIn.address}&in=${asset}&out=&ina=${inAmount}&outa=&start=${start}`,
//   )
// }

// export async function walletWithdrawSavers({ wallets, basisPoints, asset, assetAmount }) {
//   const start = Date.now()
//   const chainIn = getAssetChain(asset)
//   const walletAsset = toRaw(wallets.find((w) => w.chain === chainIn))
//   if (!walletAsset) throw new Error('No wallet connected for the from asset')
//   const basis = parseFloat(basisPoints * 10000).toFixed(0)
//   const amount = asset == 'DASH.DASH' && assetOut.length > 0 ? 0.001 : 0.0000001
//   const memo = `-:${asset.replace('.', '/')}:${basis}}`
//   const txHash = await walletAsset.deposit({
//     amount,
//     memo,
//     asset, // On ETH, other tokens can be sent than the native currency
//   })

//   Router.push(
//     `/progress?hash=${txHash}&from=${walletAsset.address}&in=${asset}&ina=${(
//       parseFloat(assetAmount) * 1e8
//     ).toFixed(0)}&outa=&start=${start}`,
//   )
// }

export { isValidEthAddress }

export function isValidKujiraAddress(address: string) {
  try {
    const decoded = bech32.decode(address)
    if (decoded.prefix !== 'kujira' || decoded.words.length !== 32) {
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

export function isValidThorChainAddress(address: string) {
  try {
    const decoded = bech32.decode(address)
    if (decoded.prefix !== 'thor' || decoded.words.length !== 32) {
      return false
    }
    return true
  } catch (error) {
    return false
  }
}
export function isValidMayaAddress(address: string) {
  try {
    const decoded = bech32.decode(address)
    if (decoded.prefix !== 'maya' || decoded.words.length !== 32) {
      return false
    }
    return true
  } catch (error) {
    return false
  }
}

export function isValidSolanaAddress(publicKey: string): boolean {
  try {
    const address = new PublicKey(publicKey)
    return PublicKey.isOnCurve(address)
  } catch (error) {
    return false
  }
}

export function isValidDotAddress(address) {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address))

    return true
  } catch (error) {
    return false
  }
}

export function isValidBitcoinAddress(address) {
  try {
    bitcoin.address.toOutputScript(address)
    return true
  } catch (e) {
    return false
  }
}

export function isValidDashAddress(address: string) {
  try {
    // Dash network parameters
    const dashNetwork = {
      messagePrefix: '\x19DarkCoin Signed Message:\n',
      bech32: 'bc',
      bip32: {
        public: 0x02fe52f8,
        private: 0x02fe52cc,
      },
      pubKeyHash: 0x4c, // Dash addresses start with 'X'
      scriptHash: 0x10, // Dash script addresses start with '7'
      wif: 0xcc,
    }

    bitcoin.address.toOutputScript(address, dashNetwork)
    return true
  } catch (e) {
    return false
  }
}
