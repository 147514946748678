<template>
  <NuxtLayout>
    <v-app>
      <NuxtPage />
    </v-app>
    <VueQueryDevtools />
  </NuxtLayout>
</template>
<script setup>
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { useMayaRouteStore } from './store/mayaRouteStore'
import { useRouteStore } from '@/store/routeStore'

const routeStore = useRouteStore()
const mayaRouteStore = useMayaRouteStore()

onMounted(async () => {
  // Mava Widget is only visible with the docked devtools closed
  const mava = document.createElement('script')
  mava.src = 'https://widget.mava.app'
  mava.defer = true
  mava.id = 'MavaWebChat'
  mava.setAttribute('widget-version', 'v2')
  mava.setAttribute('enable-sdk', 'false')
  mava.setAttribute(
    'data-token',
    '675ad5061fc96f09460ef68ed200885b639ca4915fc32a998251e7ffe404cf1f',
  )
  mava.async = true
  document.body.appendChild(mava)

  await mayaRouteStore.fetchPools() // TODO move this to a store wrapper function
  routeStore.initRoutes()

  routeStore.initLpPools()
})
</script>
