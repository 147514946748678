import { captureException } from '@sentry/browser'
import { useChainflipRouteStore } from './chainflipRouteStore'
import { useMayaRouteStore } from './mayaRouteStore'
import { useRangoRouteStore } from './rangoRouteStore'
import type { Quote, RouteEndPoint } from '~/types'
interface NoncedQuotes {
  nonce: number
  quotes: Array<Quote>
}
export const useRouteStore = defineStore('routeStore', {
  state: () => ({
    routes: [] as Array<RouteEndPoint>,
    lpPools: [] as Array<LpPool>,
    chainMap: {},
  }),
  getters: {
    getRoutes: (state) => state.routes,
    getAssetSelectorData: (state) => {
      const result = state.routes.reduce((acc, { asset, assetName, chain, integrations }) => {
        // Check if the chain already exists in the accumulator
        if (!acc[chain]) {
          acc[chain] = [] // Initialize an empty array if the chain doesn't exist
        }

        // Push an object containing both the asset and its integrations
        acc[chain].push({ asset, assetName, integrations })

        return acc
      }, {})

      // Convert the result into an array of objects with 'chain' as the key
      const finalArray = Object.keys(result).map((chain) => ({
        chain,
        assets: result[chain],
      }))

      return finalArray
    },
    getLpPoolSelectorData: (state) => {
      const result = state.lpPools.reduce((acc, { asset, assetName, chain, integrations }) => {
        // Check if the chain already exists in the accumulator
        if (!acc[chain]) {
          acc[chain] = [] // Initialize an empty array if the chain doesn't exist
        }

        // Push an object containing both the asset and its integrations
        acc[chain].push({ asset, assetName, integrations })

        return acc
      }, {})

      // Convert the result into an array of objects with 'chain' as the key
      const finalArray = Object.keys(result).map((chain) => ({
        chain,
        assets: result[chain],
      }))
      return finalArray.filter((p) => p.chain !== 'MAYA')
    },
  },
  actions: {
    async initLpPools() {
      // Fetch and add available pools
      const response = await nodeRequest('/pools')
      const pools: LpPool[] = response
        .filter((pool) => pool.status === 'Available' || pool.status === 'Staged')
        .map((pool) => ({
          asset: pool.asset,
          assetName: getAssetName(pool.asset),
          chain: getAssetChain(pool.asset),
          status: pool.status,
          balance_asset: parseInt(pool.balance_asset),
          balance_cacao: parseInt(pool.balance_cacao),
          integrations: ['maya'],
        }))

      this.lpPools = pools
    },

    initRoutes() {
      const chainflipRouteStore = useChainflipRouteStore()
      const { routes: routesChainflip } = storeToRefs(chainflipRouteStore)

      const mayaRouteStore = useMayaRouteStore()
      const { routes: routesMaya } = storeToRefs(mayaRouteStore)

      const rangoRouteStore = useRangoRouteStore()
      const { routes: routesRango } = storeToRefs(rangoRouteStore)

      // Start with chainflip routes
      this.routes = routesChainflip.value

      // Append or merge maya routes
      routesMaya.value.forEach((routeMaya) => {
        // Find the corresponding route in this.routes
        const existingRoute = this.routes.find((route) => route.asset === routeMaya.asset)

        if (existingRoute) {
          // Merge integrations from routeMaya into existingRoute
          existingRoute.integrations = [
            ...new Set([...existingRoute.integrations, ...routeMaya.integrations]),
          ]
        } else {
          // If the routeMaya does not exist in this.routes, add it
          this.routes.push(routeMaya)
        }
      })

      // Append or merge rango routes
      routesRango.value.forEach((routeRango) => {
        // Find the corresponding route in this.routes
        const existingRoute = this.routes.find((route) => route.asset === routeRango.asset)

        if (existingRoute) {
          // Merge integrations from routeMaya into existingRoute
          existingRoute.integrations = [
            ...new Set([...existingRoute.integrations, ...routeRango.integrations]),
          ]
        } else {
          // If the routeRango does not exist in this.routes, add it
          this.routes.push(routeRango)
        }
      })

      // Sort pools so nativeAsset is 1st and then by order, then the rest of the assets
      const order = [
        'BTC.BTC',
        'ETH.ETH',
        'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
        'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7',
        'DOT.DOT',
        'KUJI.KUJI',
        'THOR.RUNE',
        'DASH.DASH',
        'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
        'KUJI.USK',
        'SOLANA.SOL',
      ]
      this.routes = this.routes.sort((a, b) => {
        // Check if either asset is the nativeAsset and prioritize it
        if (a.asset === nativeAsset) return -1
        if (b.asset === nativeAsset) return 1

        // Check if assets are in the order array and get their indices
        const indexA = order.indexOf(a.asset)
        const indexB = order.indexOf(b.asset)

        // Handle assets not in the order array by placing them at the end
        if (indexA === -1 && indexB === -1) return 0 // Both assets not in order, keep their relative order
        if (indexA === -1) return 1 // a is not in order, sort b before a
        if (indexB === -1) return -1 // b is not in order, sort a before b

        // Both assets are in the order array, sort by their order
        return indexA - indexB
      })
    },
    async fetchQuotes(
      nonce: number,
      poolIn: RouteEndPoint,
      poolOut: RouteEndPoint,
      amount: string,
      valueIn: number,
    ): Promise<NoncedQuotes> {
      const chainflipRouteStore = useChainflipRouteStore()
      const mayaRouteStore = useMayaRouteStore()
      const rangoRouteStore = useRangoRouteStore()

      const fetchQuotesFunctions = {
        chainflip: chainflipRouteStore.fetchQuote,
        maya: mayaRouteStore.fetchQuote,
        rango: rangoRouteStore.fetchQuote,
      }
      type FetchQuoteFunctions = typeof fetchQuotesFunctions
      type Integration = keyof FetchQuoteFunctions
      type FetchQuote = FetchQuoteFunctions[Integration]

      const quotePromises: Array<ReturnType<FetchQuote>> = []

      for (const integration of poolIn.integrations) {
        if (poolOut.integrations.includes(integration)) {
          const fetchQuote: FetchQuoteFunctions[Integration] | undefined =
            fetchQuotesFunctions[integration as Integration]
          if (!fetchQuote) {
            captureException(`Integration not found: {integration}`)
            // TODO add analytics here
          }
          quotePromises.push(fetchQuote(poolIn, poolOut, amount, valueIn))
        }
      }
      const quotes = await Promise.all(quotePromises)
      return {
        nonce,
        quotes,
      }
    },
    fetchIntegrationStatus(
      integration: 'maya' | 'chainflip' | 'rango', // TODO import as constant
      statusId: string,
      query?: any,
    ): Array<Promise<any>> {
      const chainflipRouteStore = useChainflipRouteStore()
      const mayaRouteStore = useMayaRouteStore()
      const rangoRouteStore = useRangoRouteStore()

      const fetchStatusFunctions = {
        chainflip: chainflipRouteStore.fetchStatus,
        maya: mayaRouteStore.fetchStatus,
        rango: rangoRouteStore.fetchStatus,
      }

      return fetchStatusFunctions[integration](statusId, query)
    },
    submitIntegrationSwap({
      poolIn,
      poolOut,
      amount,
      valueIn,
      quoteAmount,
      destination,
      integration,
      signingWallet,
    }) {
      const chainflipRouteStore = useChainflipRouteStore()
      const mayaRouteStore = useMayaRouteStore()
      const rangoRouteStore = useRangoRouteStore()

      const submitSwapFunctions = {
        chainflip: chainflipRouteStore.submitSwap,
        maya: mayaRouteStore.submitSwap,
        rango: rangoRouteStore.submitSwap,
      }

      if (integration) {
        if (
          !poolIn.integrations.includes(integration) ||
          !poolOut.integrations.includes(integration) ||
          !submitSwapFunctions[integration]
        ) {
          captureException(`Integration not found: ${integration}`)
          // TODO add analytics here
          // TODO throw error to show in swap.vue error div
        }
        const submitSwap = submitSwapFunctions[integration]
        return submitSwap({
          poolIn,
          poolOut,
          amount,
          valueIn,
          quoteAmount,
          destination,
          signingWallet,
        })
      }
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRouteStore, import.meta.hot))
}
