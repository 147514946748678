import type { TransactionRequest } from '@ethersproject/abstract-provider/src.ts/index'
import { type EvmTransaction, RangoClient } from 'rango-sdk-basic'
import { captureException } from '@sentry/browser'

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export type ReferralDetails = {
  referrerAddress: string
  referrerFee: string
}

/**
 *
 * @param valueIn - dollar amount
 */
export function getReferralDetails(
  valueIn: number,
  blockchainIn: string,
  referrers: Record<string, string>,
): [number, ReferralDetails] | [0] {
  const referrerFee = valueIn < 250 ? 0 : 0.45
  /**
   * decimal part of percentage
   */
  const affiliateBps = referrerFee * 100
  const referrerAddress = referrers[blockchainIn.toUpperCase()]
  if (referrerFee > 0 && referrerAddress) {
    return [
      affiliateBps,
      {
        referrerAddress,
        referrerFee: referrerFee.toString(),
      },
    ]
  }
  return [0]
}

export function prepareEvmTransaction(
  evmTx: EvmTransaction,
  isApprove: boolean,
): TransactionRequest {
  const gasPrice =
    !!evmTx.gasPrice && !evmTx.gasPrice.startsWith('0x')
      ? '0x' + parseInt(evmTx.gasPrice).toString(16)
      : null
  const manipulatedTx = {
    ...evmTx,
    gasPrice,
  }

  let tx = {}
  if (manipulatedTx.from) tx = { ...tx, from: manipulatedTx.from }
  if (isApprove) {
    if (manipulatedTx.approveTo) tx = { ...tx, to: manipulatedTx.approveTo }
    if (manipulatedTx.approveData) tx = { ...tx, data: manipulatedTx.approveData }
  } else {
    if (manipulatedTx.txTo) tx = { ...tx, to: manipulatedTx.txTo }
    if (manipulatedTx.txData) tx = { ...tx, data: manipulatedTx.txData }
    if (manipulatedTx.value) tx = { ...tx, value: manipulatedTx.value }
    if (manipulatedTx.gasLimit) tx = { ...tx, gasLimit: manipulatedTx.gasLimit }
    if (manipulatedTx.gasPrice) tx = { ...tx, gasPrice: manipulatedTx.gasPrice }
  }
  return tx
}

export async function checkApprovalSync(requestId: string, txId: string, rangoClient: RangoClient) {
  while (true) {
    const approvalResponse = await rangoClient.isApproved(requestId, txId)
    if (approvalResponse.isApproved) {
      return true
    }
    await sleep(3000)
  }
}

export async function checkTransactionStatusSync(
  requestId: string,
  txId: string,
  rangoClient: RangoClient,
) {
  try {
    const txStatus = await rangoClient.status({
      requestId,
      txId,
    })
    return txStatus

    // if (txStatus) {
    //   // show latest status of the transaction to the user
    //   if (
    //     !!txStatus.status &&
    //     [TransactionStatus.FAILED, TransactionStatus.SUCCESS].includes(txStatus.status)
    //   ) {
    //   }
    // }
    // deploy 2
  } catch (error) {
    captureException(`error :>> ${error}`)
    throw new Error(error)
  }
}

export function mayaDepositMemoForRango(
  memo: string | null,
  minOutAmountMayaStr: string,
  affiliateBps: number,
): string | null {
  if (memo === null) return null
  const rangoMemo = memo // The memo of transaction, can be null
  const parts = rangoMemo.split(':')
  const limitIntervalQuantity = parts[parts.length - 3].split('/')
  limitIntervalQuantity[0] = minOutAmountMayaStr
  parts[parts.length - 3] = limitIntervalQuantity.join('/')
  parts[parts.length - 2] = 'ELD'
  parts[parts.length - 1] = affiliateBps.toString()
  const eldoMemo = parts.join(':')

  return eldoMemo
}
