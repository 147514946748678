import { BigNumber } from 'bignumber.js'
import { type Asset, type Chain, SwapSDK } from '@chainflip/sdk/swap'
import type { Fee, Quote, RouteEndPoint } from '~/types'
// Initialize SDK
// TODO move this to a wrapped store function
const swapSDK = new SwapSDK({
  network: 'mainnet',
  broker: {
    url: 'https://node.eldorado.market/chainflip-broker',
    commissionBps: 45, // basis points, i.e. 100 = 1%
  },
})

const chainFlipChainMap: { [key: string]: string } = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  DOT: 'Polkadot',
  ARB: 'Arbitrum',
}

export const useChainflipRouteStore = defineStore('chainflipRoute', {
  state: () => ({
    routes: [
      {
        asset: 'ETH.ETH',
        assetName: 'ETH',
        chain: 'ETH',
        iconUrl: '/tokens/eth.png',
        integrations: ['chainflip'],
      },
      {
        asset: 'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
        assetName: 'USDC',
        chain: 'ETH',
        iconUrl: '/tokens/usdc.png',
        integrations: ['chainflip'],
      },
      {
        asset: 'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
        assetName: 'FLIP',
        chain: 'ETH',
        iconUrl: '/tokens/chainflip.svg',
        integrations: ['chainflip'],
      },
      {
        asset: 'BTC.BTC',
        assetName: 'BTC',
        chain: 'BTC',
        iconUrl: '/tokens/btc.png',
        integrations: ['chainflip'],
      },
      {
        asset: 'DOT.DOT',
        assetName: 'DOT',
        chain: 'DOT',
        iconUrl: '/tokens/polkadot.svg',
        integrations: ['chainflip'],
      },
      {
        asset: 'ARB.ETH',
        assetName: 'ETH',
        chain: 'ARB',
        iconUrl: '/tokens/eth.png',
        integrations: ['chainflip'],
      },
      {
        asset: 'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831',
        assetName: 'USDC',
        chain: 'ARB',
        iconUrl: '/tokens/usdc.png',
        integrations: ['chainflip'],
      },
    ] as Array<RouteEndPoint>,
    assetMap: {
      ETH: 'ETH.ETH',
      USDC: 'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
      FLIP: 'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
      BTC: 'BTC.BTC',
      DOT: 'DOT.DOT',
    } as { [key: string]: string },
  }),
  actions: {
    async fetchQuote(
      poolIn: RouteEndPoint,
      poolOut: RouteEndPoint,
      amount: string,
    ): Promise<Quote> {
      const srcChain = chainFlipChainMap[poolIn.chain] as Chain
      const destChain = chainFlipChainMap[poolOut.chain] as Chain
      try {
        const quoteResult = await swapSDK.getQuote({
          srcChain,
          srcAsset: poolIn.assetName as Asset,
          destChain,
          destAsset: poolOut.assetName as Asset,
          amount, // 0.1 ETH
        })

        const out = quoteResult.quote.egressAmount // (poolOut.asset === 'MAYA.CACAO' ? 1e10 : 1e8)
        const outDisplay = integerToDisplayStr(out, poolOut.asset)

        const fees: Fee[] = quoteResult.quote.includedFees
          .filter((fee) => fee.type === 'BROKER')
          .map((fee) => {
            const asset = this.assetMap[fee.asset]
            const assetName = fee.asset

            const feeAmount = new BigNumber(fee.amount).div(10 ** getAssetDecimals(asset))
            const amountDisplay = formatNumber(feeAmount, DISPLAY_DECIMALS[asset])
            return {
              name: 'Affiliate Fee (.45%)',
              asset,
              assetName,
              amount: feeAmount,
              amountDisplay,
            }
          })

        // const displayFee = formatNumber(
        //   fees.reduce((acc, fee) => {
        //     return acc + parseFloat(fee.amountDisplay)
        //   }, 0),
        //   2,
        // )

        // const displayFeeAsset = formatNumber(
        //   parseFloat(displayFee) / assetPrices.value[poolOut.asset.replace('/', '.')],
        //   DISPLAY_DECIMALS[poolOut.asset],
        // )

        const valueOut = new BigNumber(quoteResult.quote.egressAmount).div(
          10 ** getAssetDecimals(poolOut.asset),
        )

        return {
          out,
          outDisplay,
          fees,
          isLoading: false,
          error: '',
          isVisible: true,
          integration: 'chainflip',
          quoteId: 'chainflip-0',
          valueOut,
        }
      } catch (error) {
        return {
          out: '0',
          outDisplay: '0',
          fees: [],
          isLoading: false,
          error: error?.response?.data?.message ?? error.message,
          isVisible: true,
          integration: 'chainflip',
          quoteId: 'chainflip-0',
          valueOut: new BigNumber(0),
        }
      }
    },
    async fetchStatus(statusId: string): Promise<any> {
      const response = await swapSDK.getStatus({
        id: statusId,
      })

      const statusDate = response.broadcastSucceededAt || response.swapExecutedAt

      const swapStatus = {
        start: response.depositChannelCreatedAt,
        status: response.state,
        date: statusDate ? new Date(parseInt(statusDate)) : null,
        hash: '',
        outAddress: response.destAddress,
        outAmount: response.egressAmount,
        error: '',
      }

      return swapStatus
    },
    async submitSwap({ poolIn, poolOut, amount, quoteAmount, destination, signingWallet }) {
      const router = useRouter()

      const requestDepositParams = {
        srcChain: chainFlipChainMap[poolIn.chain],
        srcAsset: poolIn.assetName,
        destChain: chainFlipChainMap[poolOut.chain],
        destAsset: poolOut.assetName,
        amount,
        destAddress: destination,
      }

      // Request deposit address for swap
      const depositAddress = await swapSDK.requestDepositAddress(requestDepositParams)

      const amountFl = bigIntToDecimalString(BigInt(amount), getAssetDecimals(poolIn.asset))

      // Get signing wallet instance and transfer amountIn to depositAddress
      const txHash = await signingWallet.transfer({
        to: depositAddress.depositAddress,
        amount: amountFl,
        asset: poolIn.asset,
        memo: '',
      })

      const walletInAddress = signingWallet.address
      const assetIn = poolIn.asset
      const assetOut = poolOut.asset
      const inAmount = amount
      const start = Date.now()

      const params = {
        hash: txHash,
        statusId: depositAddress.depositChannelId,
        from: walletInAddress,
        in: assetIn,
        out: assetOut,
        ina: inAmount,
        outa: quoteAmount,
        start: start.toString(),
        integration: 'chainflip',
      }

      router.push(createQueryString('/status', params))
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChainflipRouteStore, import.meta.hot))
}
