import { BigNumber } from 'bignumber.js'
import { RangoClient, type SwapRequest } from 'rango-sdk-basic'
import { captureException } from '@sentry/browser'
import { Contract, MaxUint256, ethers, parseUnits } from 'ethers'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import type { Quote, RouteEndPoint } from '~/types'
import { useSwapStore } from '@/store/swapStore'

const DEFAULT_EXCLUDED_SWAPPERS = ['Bridgers', 'SWFT']
const DEFAULT_SLIPPAGE = '4.9' // required by rango to be smaller 5 to not throw an error

const routeToSwappers = (
  route: RouteLocationNormalizedLoaded,
): { swappers: string[]; swappersExclude: boolean } => {
  const swappers = Array.isArray(route.query.swappers)
    ? route.query.swappers.filter((swapper): swapper is string => typeof swapper === 'string')
    : route.query.swappers?.split(',')
  const swappersExclude =
    (Array.isArray(route.query.swappersExclude)
      ? route.query.swappersExclude[0]
      : route.query.swappersExclude) !== 'false'
  return {
    swappers: swappers ?? DEFAULT_EXCLUDED_SWAPPERS,
    swappersExclude,
  }
}

// Initialize SDK
// TODO move this to a wrapped store function
const rangoClient = new RangoClient('', false, 'https://node.eldorado.market/rango')
// Ethena $ENA (ETH): 0x57e114B691Db790C35207b2e685D4A43181e6061
// Alchemix $ALCX (ETH): 0xdbdb4d16eda451d0503b854cf79d55697f90c8df
// Yearn $YFI (ETH): 0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e
// AAVE $AAVE (ETH): 0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9
// MOG $MOG (ETH): 0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a
// JESUS $JESUS (ETH): 0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70

// LANA DEL RAY $LDR (SOL): H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC
// SWAG $SWAG (SOL): FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor
// RETARDIO $RETARDIO (SOL): 6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx
export const useRangoRouteStore = defineStore('rangoRoute', {
  state: () => ({
    routes: [
      {
        asset: 'ETH.ETH',
        assetName: 'ETH',
        chain: 'ETH',
        iconUrl: '/tokens/eth.png',
        integrations: ['rango'],
      },
      {
        asset: 'KUJI.KUJI',
        assetName: 'KUJI',
        chain: 'KUJI',
        iconUrl: '/tokens/kuji.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
        assetName: 'FLIP',
        chain: 'ETH',
        iconUrl: '/tokens/chainflip.svg',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
        assetName: 'USDC',
        chain: 'ETH',
        iconUrl: '/tokens/usdc.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7',
        assetName: 'USDT',
        chain: 'ETH',
        iconUrl: '/tokens/usdt.png',
        integrations: ['rango'],
      },
      {
        asset: 'THOR.RUNE',
        assetName: 'RUNE',
        chain: 'THOR',
        iconUrl: '/tokens/rune.png',
        integrations: ['rango'],
      },
      {
        asset: 'MAYA.CACAO',
        assetName: 'CACAO',
        chain: 'MAYA',
        iconUrl: '/tokens/maya.png',
        integrations: ['rango'],
      },
      {
        asset: 'DASH.DASH',
        assetName: 'DASH',
        chain: 'DASH',
        iconUrl: '/tokens/dash.png',
        integrations: ['rango'],
      },
      {
        asset: 'BTC.BTC',
        assetName: 'BTC',
        chain: 'BTC',
        iconUrl: '/tokens/btc.png',
        integrations: ['rango'],
      },
      {
        asset: 'DOT.DOT',
        assetName: 'DOT',
        chain: 'DOT',
        iconUrl: '/tokens/polkadot.svg',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.SOL',
        assetName: 'SOL',
        chain: 'SOLANA',
        iconUrl: '/tokens/solana.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        assetName: 'USDC',
        chain: 'SOLANA',
        iconUrl: '/tokens/usdc.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
        assetName: 'USDT',
        chain: 'SOLANA',
        iconUrl: '/tokens/usdt.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd',
        assetName: 'ZYN',
        chain: 'SOLANA',
        iconUrl: '/tokens/zyn.jpg',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
        assetName: 'BONK',
        chain: 'SOLANA',
        iconUrl: '/tokens/bonk.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
        assetName: 'WIF',
        chain: 'SOLANA',
        iconUrl: '/tokens/wif.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82',
        assetName: 'BOME',
        chain: 'SOLANA',
        iconUrl: '/tokens/bome.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3',
        assetName: 'SLERF',
        chain: 'SOLANA',
        iconUrl: '/tokens/slerf.png',
        integrations: ['rango'],
      },

      {
        asset: 'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb',
        assetName: 'ZYN',
        chain: 'ETH',
        iconUrl: '/tokens/zyn.jpg',
        integrations: ['rango'],
      },

      {
        asset: 'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7',
        assetName: 'TRUMP',
        chain: 'ETH',
        iconUrl: '/tokens/trump.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933',
        assetName: 'PEPE',
        chain: 'ETH',
        iconUrl: '/tokens/pepe.webp',
        integrations: ['rango'],
      },
      // {
      //   asset: 'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o',
      //   assetName: 'BODEN',
      //   chain: 'SOLANA',
      //   iconUrl: '/tokens/boden.png',
      //   integrations: ['rango'],
      // },
      // {
      //   asset: 'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR',
      //   assetName: 'KENIDY',
      //   chain: 'SOLANA',
      //   iconUrl: '/tokens/kenidy.png',
      //   integrations: ['rango'],
      // },
      {
        asset: 'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb',
        assetName: 'TREN',
        chain: 'SOLANA',
        iconUrl: '/tokens/tren.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ',
        assetName: 'BARRON',
        chain: 'SOLANA',
        iconUrl: '/tokens/barron.jpeg',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa',
        assetName: 'APU',
        chain: 'ETH',
        iconUrl: '/tokens/apu.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061',
        assetName: 'ENA',
        chain: 'ETH',
        iconUrl: '/tokens/ena.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
        assetName: 'ALCX',
        chain: 'ETH',
        iconUrl: '/tokens/alcx.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
        assetName: 'YFI',
        chain: 'ETH',
        iconUrl: '/tokens/yfi.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
        assetName: 'AAVE',
        chain: 'ETH',
        iconUrl: '/tokens/aave.jpeg',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a',
        assetName: 'MOG',
        chain: 'ETH',
        iconUrl: '/tokens/mog.png',
        integrations: ['rango'],
      },
      {
        asset: 'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70',
        assetName: 'JESUS',
        chain: 'ETH',
        iconUrl: '/tokens/jesus.png',
        integrations: ['rango'],
      },
      // {
      //   asset: 'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC',
      //   assetName: 'LDR',
      //   chain: 'SOLANA',
      //   iconUrl: '/tokens/ldr.png',
      //   integrations: ['rango'],
      // },
      {
        asset: 'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor',
        assetName: 'SWAG',
        chain: 'SOLANA',
        iconUrl: '/tokens/swag.jpeg',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx',
        assetName: 'RETARDIO',
        chain: 'SOLANA',
        iconUrl: '/tokens/retardio.png',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H',
        assetName: 'WEWE',
        chain: 'SOLANA',
        iconUrl: '/tokens/wewe.webp',
        integrations: ['rango'],
      },
      {
        asset: 'SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump',
        assetName: 'DMAGA',
        chain: 'SOLANA',
        iconUrl: '/tokens/dmaga.webp',
        integrations: ['rango'],
      },
    ] as Array<RouteEndPoint>,
    chainMap: {
      KUJI: 'KUJIRA',
      KUJIRA: 'KUJI',
    } as { [key: string]: string },
    assetMap: {
      KUJI: 'KUJI.KUJI',
      CACAO: 'MAYA.CACAO',
      DASH: 'DASH.DASH',
      RUNE: 'THOR.RUNE',
      ukuji: 'KUJI.KUJI',
      BTC: 'BTC.BTC',
      'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48':
        'USDC--0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
      'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7':
        'USDT--0XDAC17F958D2EE523A2206206994597C13D831EC7',
      'SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v':
        'USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      'SOLANA.USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB':
        'USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
      'SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd':
        'ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd',
      'ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7':
        'TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7',
      'ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb':
        'ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb',
      'SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263':
        'BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
      'SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm':
        'WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
      'SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82':
        'BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82',
      'SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3':
        'SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3',
      'ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933':
        'PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933',
      'SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o':
        'BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o',
      'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933':
        'PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933',
      'SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR':
        'KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR',
      'SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb':
        'TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb',
      'ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa':
        'APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa',
      'SOLANA.BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ':
        'BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ',
      'ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061':
        'ENA--0x57e114B691Db790C35207b2e685D4A43181e6061',
      'ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df':
        'ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
      'ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e':
        'YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      'ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9':
        'AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      'ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a':
        'MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a',
      'ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70':
        'JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70',
      'SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC':
        'LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC',
      'SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor':
        'SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor',
      'SOLANA.RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx':
        'RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx',
      'SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump':
        'SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump',
      'SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H':
        'SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H',

      //   USDC: 'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48',
      //   FLIP: 'ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A',
      //   DOT: 'DOT.DOT',
    } as { [key: string]: string },
  }),
  actions: {
    resolveTokenAddress(tokenName: string): string {
      for (const key of Object.keys(this.assetMap)) {
        if (key.toUpperCase().startsWith(tokenName)) {
          return key.split('--')[1]
        }
      }
      throw new Error('Token not found')
    },
    async fetchQuote(
      poolIn: RouteEndPoint,
      poolOut: RouteEndPoint,
      amount: string,
      valueIn: number,
    ): Promise<Quote> {
      const route = useRoute()
      const rangoReferrers = useRangoReferrers()

      try {
        // const quoteResult = await swapSDK.getQuote({
        //   srcChain: poolIn.chain,
        //   srcAsset: poolIn.assetName,
        //   destChain: poolOut.chain,
        //   destAsset: poolOut.assetName,
        //   amount, // 0.1 ETH
        // })

        const blockchainIn = this.chainMap[poolIn.chain] || poolIn.chain
        const blockchainOut = this.chainMap[poolOut.chain] || poolOut.chain
        const symbolIn = this.assetMap[poolIn.asset] || poolIn.assetName
        const symbolOut = this.assetMap[poolOut.asset] || poolOut.assetName
        const [affiliateBps, referralDetails] = getReferralDetails(
          valueIn,
          blockchainIn,
          rangoReferrers,
        )

        const quoteResult = await rangoClient.quote({
          from: {
            blockchain: blockchainIn,
            symbol: symbolIn,
            address: null,
          },
          to: {
            blockchain: blockchainOut,
            symbol: symbolOut,
            address: null,
          },
          amount,
          ...(referralDetails ? { referrerFee: referralDetails.referrerFee } : null),
          enableCentralizedSwappers: true,
          ...routeToSwappers(route),
        })

        if (quoteResult.resultType !== 'OK' || quoteResult.error || quoteResult.route === null) {
          let message = 'No route available'

          if (quoteResult.error === 'Your input amount might be too high!') {
            message = 'Your input amount might be too high!'
          } else if (quoteResult.error === 'Your input amount might be too low!') {
            message = 'Your input amount might be too low!'
          }
          if (quoteResult.resultType === 'INPUT_LIMIT_ISSUE')
            message = 'No route found for you input amount, please try a different amount.'

          captureException(`quoteResult :>> ${JSON.stringify(quoteResult)}`)
          captureException(`quoteResult message :>> ${message}`)

          return {
            out: '0',
            outDisplay: '0',
            fees: [],
            isLoading: false,
            error: message,
            isVisible: true,
            integration: 'rango',
            quoteId: 'rango-0',
            valueOut: new BigNumber(0),
          }
          // throw new Error('Rango quote is null')
        }

        const out = quoteResult.route.outputAmount

        const asset = poolOut.asset

        const outDisplay = integerToDisplayStr(out, poolOut.asset)

        const valueOut = new BigNumber(out).div(10 ** quoteResult.route.to.decimals)

        const feeAmount = new BigNumber(valueOut).times(affiliateBps).div(10000)
        const fees: Fee[] = [
          {
            name: `Affiliate Fee (.${affiliateBps}%)`,
            asset,
            assetName: getAssetName(asset),
            amount: feeAmount,
            amountDisplay: formatNumber(feeAmount, DISPLAY_DECIMALS[asset]),
          },
        ]

        return {
          out,
          outDisplay,
          fees,
          isLoading: false,
          error: '',
          isVisible: true,
          integration: 'rango',
          quoteId: 'rango-0',
          valueOut,
        }
      } catch (error) {
        return {
          out: '0',
          outDisplay: '0',
          fees: [],
          isLoading: false,
          error: error.message,
          isVisible: true,
          integration: 'rango',
          quoteId: 'rango-0',
          valueOut: new BigNumber(0),
        }
      }
    },
    async fetchStatus(statusId: string, query: any): Promise<any> {
      const mainTxHash = query.hash

      const txStatus = await checkTransactionStatusSync(statusId, mainTxHash, rangoClient)

      const swapStatus = {
        start: undefined,
        status: txStatus.status,
        date: null,
        hash: mainTxHash,
        outAddress: undefined,
        outAmount: txStatus.output?.amount,
        error: txStatus.error,
        explorerUrls: txStatus.explorerUrl?.map((url) => ({
          ...url,
          urlText: url.url.split('/')[url.url.split('/').length - 1],
        })),
      }
      return swapStatus
    },
    async submitSwap({
      poolIn,
      poolOut,
      amount,
      valueIn,
      quoteAmount,
      destination,
      signingWallet,
    }) {
      const router = useRouter()
      const route = useRoute()
      const runtimeConfig = useRuntimeConfig()
      const rangoReferrers = useRangoReferrers()

      const blockchainIn = this.chainMap[poolIn.chain] || poolIn.chain
      const blockchainOut = this.chainMap[poolOut.chain] || poolOut.chain

      const symbolIn = this.assetMap[poolIn.asset] || poolIn.assetName
      const symbolOut = this.assetMap[poolOut.asset] || poolOut.assetName

      const [, referralDetails] = getReferralDetails(valueIn, blockchainIn, rangoReferrers)

      const swapParameters: SwapRequest = {
        from: {
          blockchain: blockchainIn,
          symbol: symbolIn,
          address: null,
        },
        to: {
          blockchain: blockchainOut,
          symbol: symbolOut,
          address: null,
        },
        amount,
        fromAddress: signingWallet.address,
        toAddress: destination,
        slippage: DEFAULT_SLIPPAGE,
        disableEstimate: true,
        enableCentralizedSwappers: true,
        enableCentralized: true,
        referrerCode: runtimeConfig.public.RANGO_CODE,
        ...referralDetails,
        ...routeToSwappers(route),
      }

      const swap = await rangoClient.swap(swapParameters)

      if (!!swap.error || swap.resultType !== 'OK') {
        const msg = `Error swapping, message: ${swap.error}, status: ${swap.resultType}`
        captureException(msg)
        throw new Error(msg)
      }

      if (!swap.tx) {
        const msg = `Error swapping, no tx`
        captureException(msg)
        throw new Error(msg)
      }

      // Handle sign and broadcast for the different blockchain types
      let mainTxHash: string | undefined

      if (swap.tx.type === 'EVM') {
        const signer = signingWallet.signer || (await signingWallet.provider.getSigner())

        if (signingWallet.xDefiObject?.installed) {
          // In case we need to handle network changes more specifically, example:
          // try {
          //   const network = await signer.provider.getNetwork()
          //   // arbitrum: network.chainId === 42161n
          //   // ethereum: network.chainId === 1n
          // } catch (e) {
          //   if (e.message.includes('network changed')) {
          //     console.log('network changed')
          //     // show network change modal
          //     alert('check network on your wallet: ' + e.message)
          //     // or switchEthereumChain ...
          //   } else {
          //     console.error('Error getting chainId', e)
          //   }
          //   return
          // }

          // TODO if supporting `ARB` or other chains, handle this here as well
          await window.xfi.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
          })
        }

        const evmTransaction = swap.tx

        // needs approving the tx
        if (swap.approveTo && swap.approveData) {
          const approveTx = prepareEvmTransaction(evmTransaction, true)

          const approveTxHash = (await signer.sendTransaction(approveTx)).hash
          await checkApprovalSync(swap.requestId, approveTxHash, rangoClient)
        }

        // main transaction

        const mainTx = prepareEvmTransaction(evmTransaction, false)

        mainTxHash = (await signer.sendTransaction(mainTx)).hash // TODO move evm code to wallet class
      }
      if (swap.tx.type === 'TRANSFER') {
        const to = swap.tx.recipientAddress // Destination wallet address that the fund should be sent to
        const memo = swap.tx.memo // The memo of transaction, can be null
        const asset = this.assetMap[swap.tx.asset.symbol] // The asset of transaction
        const amountFl = IntStringToDecimalString(swap.tx.amount, getAssetDecimals(asset)) // The machine-readable amount of transaction

        switch (swap.tx.asset.blockchain) {
          case 'MAYA':
            if (swap.tx.method === 'deposit' && memo !== null) {
              mainTxHash = await signingWallet.deposit({
                amount: amountFl,
                asset,
                memo,
              })
            } else if (swap.tx.method === 'transfer' && memo !== null) {
              mainTxHash = await signingWallet.transfer({
                amount: amountFl,
                asset,
                memo,
              })
            } else {
              captureException(`Unsupported method or memo: ${swap.tx.method}, ${memo}`)
              throw new Error('Unsupported method or memo: ' + swap.tx.method + ', ' + memo)
            }
            break
          case 'THOR':
            if (swap.tx.method === 'deposit' && memo !== null) {
              mainTxHash = await signingWallet.depositRuneOnThorchain({
                to,
                amount: amountFl,
                asset,
                memo,
              })
            } else if (
              swap.tx.method === 'transfer' &&
              memo !== null &&
              'transferThorchain' in signingWallet
            ) {
              mainTxHash = await signingWallet.transferThorchain({
                to,
                amount: amountFl,
                asset,
                memo,
              })
            } else {
              captureException(`Unsupported method or memo: ${swap.tx.method}, ${memo}`)
              throw new Error('Unsupported method or memo: ' + swap.tx.method + ', ' + memo)
            }

            break
          case 'DASH':
            mainTxHash = await signingWallet.transferDash({
              to,
              amount: amountFl,
              memo,
            })
            break
          case 'BTC':
            mainTxHash = await signingWallet.transferBitcoin({ to, amount: amountFl, memo })
            break
          default:
            captureException(`Unsupported blockchain: ${swap.tx.asset.blockchain}`)
            throw new Error('Unsupported blockchain: ' + swap.tx.asset.blockchain)
        }
      }

      if (swap.tx.type === 'COSMOS') {
        const msgs = swap.tx.data.msgs
        const msg = msgs[0]
        if (msg.type === 'cosmos-sdk/MsgSend') {
          const to = msg.value.to_address // Destination wallet address that the fund should be sent to
          const coin = msg.value.amount[0]
          const amountFl = IntStringToDecimalString(coin.amount, getAssetDecimals(coin.denom))
          if (swap.tx?.blockChain === 'KUJIRA') {
            const asset = this.assetMap[coin.denom] // The asset of transaction
            const memo = swap.tx.data.memo
            mainTxHash = await signingWallet.transferKujira({
              to,
              amount: amountFl,
              asset,
              memo,
            })
          }
        }
      }

      if (swap.tx.type === 'SOLANA') {
        mainTxHash = await signingWallet.signAndSendTransaction(swap.tx)
      }

      // Params for status page
      const walletInAddress = signingWallet.address
      const assetIn = poolIn.asset
      const assetOut = poolOut.asset
      const inAmount = amount
      const start = Date.now()
      const params = {
        hash: mainTxHash,
        statusId: swap.requestId,
        from: walletInAddress,
        in: assetIn,
        out: assetOut,
        ina: inAmount,
        outa: quoteAmount,
        start: start.toString(),
        integration: 'rango',
      }
      router.push({ path: '/status', query: params })
    },
    async checkTokenApprovalStatusRango({
      checkOrApprove,
      poolIn,
      poolOut,
      toAddress,
      amountIn,
      signer,
      chain,
      gasOracleURL,
    }: CheckTokenApprovalStatusRangoParams) {
      const {
        // gettingTransactionFee,
        checkingTokenApproval,
        needsTokenApproval,
        // approveEstimatedFee,
        approveTokenStatus,
      } = storeToRefs(useSwapStore())
      // console.log('ApproveToken checkTokenApprovalStatusRango rangoRouteStore entry')
      // console.log(
      //   'ApproveToken checkTokenApprovalStatusRango poolIn==' + JSON.stringify(poolIn.value),
      // )
      try {
        // console.log(
        //   'ApproveToken checkTokenApprovalStatusRango poolOut==' + JSON.stringify(poolOut.value),
        // )
        if (signer !== undefined) {
          // console.log(
          //   'ApproveToken checkTokenApprovalStatusRango chainMap==' + JSON.stringify(this.chainMap),
          // )
          // console.log(
          //   'ApproveToken checkTokenApprovalStatusRango assetMap==' + JSON.stringify(this.assetMap),
          // )
          const blockchainIn = this.chainMap[poolIn.chain] || poolIn.chain
          const blockchainOut = this.chainMap[poolOut.chain] || poolOut.chain
          const symbolIn = this.assetMap[poolIn.asset] || poolIn.assetName
          const symbolOut = this.assetMap[poolOut.asset] || poolOut.assetName

          // console.log('ApproveToken checkTokenApprovalStatusRango blockchainIn==' + blockchainIn)
          // console.log('ApproveToken checkTokenApprovalStatusRango blockchainOut==' + blockchainOut)
          // console.log('ApproveToken checkTokenApprovalStatusRango symbolIn==' + symbolIn)
          // console.log('ApproveToken checkTokenApprovalStatusRango symbolOut==' + symbolOut)

          let tokenAddress = (symbolIn.split('-')[1] || '').toLowerCase()
          if (!tokenAddress) {
            tokenAddress = (symbolIn.split('--')[1] || '').toLowerCase()
          }
          if (!tokenAddress) {
            tokenAddress = '0x0000000000000000000000000000000000000000'
          }
          // console.log('ApproveToken checkTokenApprovalStatusRango tokenAddress==' + tokenAddress)

          const contractToken = new Contract(
            tokenAddress,
            [
              'function decimals() view returns (uint8)',
              'function allowance(address owner, address spender) view returns (uint)',
              'function approve(address spender, uint value)',
            ],
            signer,
          )

          let decimals = 18
          try {
            decimals = await contractToken.decimals()
          } catch (e) {
            console.error('ApproveToken getting decimal error==' + e)
          }

          // console.log('ApproveToken checkTokenApprovalStatusRango decimals==' + decimals)

          const amount = parseUnits(amountIn, decimals)

          // console.log('ApproveToken checkTokenApprovalStatusRango amount==' + amount)

          const swapParameters: SwapRequest = {
            from: {
              blockchain: blockchainIn,
              symbol: symbolIn,
              address: null,
            },
            to: {
              blockchain: blockchainOut,
              symbol: symbolOut,
              address: null,
            },
            amount: amount.toString(),
            fromAddress: signer.address,
            toAddress,
            slippage: DEFAULT_SLIPPAGE,
            disableEstimate: false,
            enableCentralizedSwappers: true,
            enableCentralized: true,
            referrerAddress: null,
            referrerFee: null,
          }

          const swap = await rangoClient.swap(swapParameters)

          // console.log('ApproveToken checkTokenApprovalStatusRango swap==' + JSON.stringify(swap))

          if (!!swap.error || swap.resultType !== 'OK') {
            const msg = `Error checking Rango approval, message: ${swap.error}, status: ${swap.resultType}`
            captureException(msg)
            throw new Error(msg)
          }

          if (!swap.tx) {
            const msg = `Error checking Rango approval, no tx`
            captureException(msg)
            throw new Error(msg)
          }

          // console.log(
          //   'ApproveToken checkTokenApprovalStatusRango swap.tx==' + JSON.stringify(swap.tx),
          // )

          // const evmTransaction = swap.tx as EvmTransaction

          if (swap.tx.type === 'EVM') {
            // const evmTransaction = swap.tx as EvmTransaction

            // console.log(
            //   'ApproveToken checkTokenApprovalStatusRango swap.tx.approveTo==' + swap.tx.approveTo,
            // )
            // console.log(
            //   'ApproveToken checkTokenApprovalStatusRango swap.tx.approveData==' +
            //     swap.tx.approveData,
            // )

            // needs approving the tx
            if (swap.tx.approveTo && swap.tx.approveData) {
              if (checkOrApprove === 'check') {
                // console.log(
                //   'ApproveToken checkTokenApprovalStatusRango setting needsTokenApproval true',
                // )
                needsTokenApproval.value = true
              }
              if (checkOrApprove === 'fee') {
                // console.log('ApproveToken checkTokenApprovalStatusRango getting token approve fee')
                // const approveTx = prepareEvmTransaction(evmTransaction, true)

                const approveEstimatedGas = await contractToken.approve.estimateGas(
                  swap.tx.txTo,
                  MaxUint256,
                )
                // console.log(
                //   'ApproveToken checkTokenApprovalStatusRango approveEstimatedGas==' +
                //     approveEstimatedGas,
                // )
                // console.log('ApproveToken checkTokenApprovalStatusRango chain==' + chain)
                let gasPriceEth = 0
                if (chain === 'ETH') {
                  const gasOracle = await (await fetch(gasOracleURL)).json()
                  gasPriceEth = gasOracle.result.ProposeGasPrice / 1e9 // gwei to eth
                }

                if (chain === 'ARB') {
                  const url =
                    'https://arbitrum-mainnet.core.chainstack.com/706562b7f66620d15f1693ebb7c70157' // TODO hide api key behind reverse proxy
                  const gasFeeProvider = new ethers.JsonRpcProvider(url)
                  gasPriceEth = parseInt(await gasFeeProvider.send('eth_gasPrice', {})) / 1e18
                }

                const approveEstimatedFee = gasPriceEth * parseFloat(approveEstimatedGas.toString())
                // console.log('check approve checkTokenApprovalStatusRango==' + approveEstimatedFee)
                return approveEstimatedFee
              }
              if (checkOrApprove === 'approve') {
                await (await contractToken.approve(swap.tx.txTo, MaxUint256)).wait()
                // console.log('ApproveToken checkTokenApprovalStatusRango approving token done')
                approveTokenStatus.value = 'Approved'
              }
            }
          }
        }
      } catch (e) {
        if (checkOrApprove === 'approve') {
          approveTokenStatus.value = 'Confirm'
        }
        if (checkOrApprove === 'check') {
          needsTokenApproval.value = true
        }
        console.error(e)
        const errorMessage =
          e instanceof Error ? e.message : 'An error occurred while checking the token allowance.'
        alert(errorMessage)
      }
      if (checkOrApprove === 'check') {
        checkingTokenApproval.value = false
      }
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRangoRouteStore, import.meta.hot))
}
