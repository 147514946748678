import { BigNumber } from 'bignumber.js'
import type { Fee, Quote, RouteEndPoint, SubmitSwapParams } from '~/types'

export const useMayaRouteStore = defineStore('mayaRoute', {
  state: () => ({
    routes: [] as Array<RouteEndPoint>,
    chainMap: {},
    assetMap: {},
  }),
  getters: {
    getPools: (state) => state.routes,
  },
  actions: {
    async fetchPools() {
      // Fetch and add available pools
      let pools = await nodeRequest('/pools')

      pools = pools.filter((pool) => pool.status === 'Available')
      pools = pools.map((pool) => ({
        asset: pool.asset,
        assetName: getAssetName(pool.asset),
        chain: getAssetChain(pool.asset),
        integrations: ['maya'],
      }))

      // Add sAssets to pools
      for (const pool of [...pools]) {
        if (pool.asset === nativeAsset) continue
        const sAsset = pool.asset.replace('.', '/')
        const sPool = {
          asset: sAsset,
          chain: getAssetChain(sAsset),
          assetName: getAssetName(sAsset),
          integrations: pool.integrations,
        }
        pools = pools.concat([sPool])
      }

      // Add MAYA.CACAO to pools
      pools = pools.concat({
        asset: nativeAsset,
        assetName: getAssetName(nativeAsset),
        chain: getAssetChain(nativeAsset),
        integrations: ['maya'],
      })
      this.routes = pools
    },
    async fetchQuote(
      poolIn: RouteEndPoint,
      poolOut: RouteEndPoint,
      amount: string,
      valueIn: number,
    ): Promise<Quote> {
      const amountMaya = assetIntegerToMayaInteger(amount, poolIn.asset)

      const affiliateBps = valueIn < 250 ? 0 : 45

      // Disable Streaming Swaps TO synth assets
      const isToAssetSynth = poolOut.asset.includes('/')
      const streamingQueryParam = isToAssetSynth ? '' : '&streaming_interval=5'

      const requestUrl = `/quote/swap?from_asset=${poolIn.asset}&to_asset=${poolOut.asset}&amount=${amountMaya}&destination=${dummyDestinations[poolOut.chain]}&affiliate=eld&affiliate_bps=${affiliateBps}${streamingQueryParam}`

      const quoteResult = await nodeRequest(requestUrl)

      if (quoteResult.error) {
        return {
          out: '0',
          outDisplay: '0',
          fees: [],
          isLoading: false,
          error: quoteResult.error,
          isVisible: true,
          integration: 'maya',
          quoteId: 'maya-0',
          valueOut: new BigNumber(0),
        }
      }

      const out = mayaIntegerToAssetInteger(quoteResult.expected_amount_out, poolOut.asset) // (poolOut.asset === 'MAYA.CACAO' ? 1e10 : 1e8)
      const outDisplay = integerToDisplayStr(out, poolOut.asset)

      const { asset } = quoteResult.fees

      // const slippage = integerToDisplayStr(
      //   ((BigInt(quoteResult.slippage_bps) * BigInt(amount)) / 100n).toString(),
      //   poolIn.asset,
      // )

      // const displayFee = formatNumber(
      //   fees.reduce((acc, fee) => {
      //     return acc + parseFloat(fee.amountDisplay)
      //   }, 0),
      //   2,
      // )

      // const displayFeeAsset = formatNumber(
      //   parseFloat(displayFee) / assetPrices.value[poolOut.asset.replace('/', '.')],
      //   DISPLAY_DECIMALS[poolOut.asset],
      // )

      const valueOut = new BigNumber(out).div(10 ** getAssetDecimals(poolOut.asset))
      /**
       * if the fee is 0.45% and affiliate fee is 45
       * firstly we divide by 100 to get 0.45
       * and divide by 100 again to get 0.0045
       */
      const feeAmount = new BigNumber(valueOut).multipliedBy(affiliateBps).div(10000)
      const fees: Fee[] = [
        {
          name: `Affiliate Fee (.${affiliateBps}%)`,
          asset,
          assetName: getAssetName(asset),
          amount: feeAmount,
          amountDisplay: formatNumber(feeAmount.toNumber(), DISPLAY_DECIMALS[asset]),
        },
      ]

      return {
        out,
        outDisplay,
        fees,
        isLoading: false,
        error: '',
        isVisible: true,
        integration: 'maya',
        quoteId: 'maya-0',
        valueOut,
      }
    },
    async submitSwap({
      poolIn,
      poolOut,
      amount,
      valueIn,
      quoteAmount,
      destination,
      signingWallet,
    }: SubmitSwapParams) {
      const router = useRouter()
      const inAmountFl = bigIntToDecimalString(BigInt(amount), getAssetDecimals(poolIn.asset))

      const inAmountMaya = assetIntegerToMayaInteger(amount, poolIn.asset)
      const outAmountMaya = assetIntegerToMayaInteger(quoteAmount, poolOut.asset)
      const minOutAmountMayaStr = ((BigInt(outAmountMaya) / 100n) * 90n).toString()

      if (BigInt(quoteAmount) === 0n) {
        // TODO MOVE THIS CHECK TO THE QUOTE ARRAY so we can access the quote error
        throw new Error('Pool error: ') // + quote.value.error)
      }

      const walletInAddress = signingWallet.address
      const assetIn = poolIn.asset
      let assetOut = poolOut.asset
      const start = Date.now()

      const originalAsset = assetOut
      if (assetOut.includes('-')) {
        assetOut = assetOut.split('-')[0]
      }
      const affiliateBps = valueIn < 250 ? 0 : 45
      const minAmountMemo = affiliateBps === 0 ? '0' : minOutAmountMayaStr

      // Disable Streaming Swaps TO synth assets
      const isToAssetSynth = poolOut.asset.includes('/')
      const streamingMemo = isToAssetSynth ? '' : '/5/0'

      const memo = `=:${assetOut}:${destination}:${minAmountMemo}${streamingMemo}:ELD:${affiliateBps}`

      const payload = {
        amount: inAmountFl,
        memo, // TODO include min amount out once it accounts for fees
        asset: assetIn, // On ETH, other tokens can be sent than the native currency
      }

      const txHash = await signingWallet.deposit(payload)
      // va.track('Swap' + poolIn.value.asset, {
      //   AssetIn: poolIn.value.asset,
      //   Amount: amountIn.value,
      //   AssetOut: poolOut.value.asset,
      // })

      const params = {
        hash: txHash,
        statusId: walletInAddress,
        from: walletInAddress,
        in: assetIn,
        out: originalAsset,
        ina: inAmountMaya,
        outa: outAmountMaya,
        start: start.toString(),
        integration: 'maya',
      }

      router.push(createQueryString('/progress', params))
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMayaRouteStore, import.meta.hot))
}
