/// <reference types="nuxt/dist/app/nuxt.d.ts" />

export const useRangoReferrers = () => {
  const runtimeConfig = useRuntimeConfig()
  return {
    ETH: runtimeConfig.public.RANGO_REFERRER_ETH,
    KUJIRA: runtimeConfig.public.RANGO_REFERRER_KUJIRA,
    MAYA: runtimeConfig.public.RANGO_REFERRER_MAYA,
    THOR: runtimeConfig.public.RANGO_REFERRER_THOR,
  }
}
