import * as Sentry from '@sentry/browser'

export default defineNuxtPlugin(
  ({
    $config: {
      public: { SENTRY_DSN, SENTRY_TRACE_TARGETS },
    },
  }) => {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: SENTRY_TRACE_TARGETS?.split(',') ?? [],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      beforeSend: (event, hint) => {
        if (process.env.NODE_ENV === 'development') {
          // https://docs.sentry.io/product/reference/search/searchable-properties/events/
          // console.error(event)
          console.error(hint.originalException || hint.syntheticException)
        }
        return event
      },
    })
  },
)
