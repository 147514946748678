import { default as addlZRckC0HoZMeta } from "/vercel/path0/pages/add.vue?macro=true";
import { default as buyPTGS4Eqc58Meta } from "/vercel/path0/pages/buy.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as progress_45mayaE4uu2ERd4vMeta } from "/vercel/path0/pages/progress-maya.vue?macro=true";
import { default as progressPLhXRfx8ZJMeta } from "/vercel/path0/pages/progress.vue?macro=true";
import { default as quests2DCHvYeT0ZMeta } from "/vercel/path0/pages/quests.vue?macro=true";
import { default as savers5DOxZsJAJ9Meta } from "/vercel/path0/pages/savers.vue?macro=true";
import { default as stats9JkEnhPwt4Meta } from "/vercel/path0/pages/stats.vue?macro=true";
import { default as statusTkkg17h3mLMeta } from "/vercel/path0/pages/status.vue?macro=true";
import { default as swapwGvG0t6ytpMeta } from "/vercel/path0/pages/swap.vue?macro=true";
import { default as walletNmivtXEuWQMeta } from "/vercel/path0/pages/wallet.vue?macro=true";
import { default as withdrawgYZU9k5xLoMeta } from "/vercel/path0/pages/withdraw.vue?macro=true";
export default [
  {
    name: addlZRckC0HoZMeta?.name ?? "add",
    path: addlZRckC0HoZMeta?.path ?? "/add",
    meta: addlZRckC0HoZMeta || {},
    alias: addlZRckC0HoZMeta?.alias || [],
    redirect: addlZRckC0HoZMeta?.redirect,
    component: () => import("/vercel/path0/pages/add.vue").then(m => m.default || m)
  },
  {
    name: buyPTGS4Eqc58Meta?.name ?? "buy",
    path: buyPTGS4Eqc58Meta?.path ?? "/buy",
    meta: buyPTGS4Eqc58Meta || {},
    alias: buyPTGS4Eqc58Meta?.alias || [],
    redirect: buyPTGS4Eqc58Meta?.redirect,
    component: () => import("/vercel/path0/pages/buy.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: progress_45mayaE4uu2ERd4vMeta?.name ?? "progress-maya",
    path: progress_45mayaE4uu2ERd4vMeta?.path ?? "/progress-maya",
    meta: progress_45mayaE4uu2ERd4vMeta || {},
    alias: progress_45mayaE4uu2ERd4vMeta?.alias || [],
    redirect: progress_45mayaE4uu2ERd4vMeta?.redirect,
    component: () => import("/vercel/path0/pages/progress-maya.vue").then(m => m.default || m)
  },
  {
    name: progressPLhXRfx8ZJMeta?.name ?? "progress",
    path: progressPLhXRfx8ZJMeta?.path ?? "/progress",
    meta: progressPLhXRfx8ZJMeta || {},
    alias: progressPLhXRfx8ZJMeta?.alias || [],
    redirect: progressPLhXRfx8ZJMeta?.redirect,
    component: () => import("/vercel/path0/pages/progress.vue").then(m => m.default || m)
  },
  {
    name: quests2DCHvYeT0ZMeta?.name ?? "quests",
    path: quests2DCHvYeT0ZMeta?.path ?? "/quests",
    meta: quests2DCHvYeT0ZMeta || {},
    alias: quests2DCHvYeT0ZMeta?.alias || [],
    redirect: quests2DCHvYeT0ZMeta?.redirect,
    component: () => import("/vercel/path0/pages/quests.vue").then(m => m.default || m)
  },
  {
    name: savers5DOxZsJAJ9Meta?.name ?? "savers",
    path: savers5DOxZsJAJ9Meta?.path ?? "/savers",
    meta: savers5DOxZsJAJ9Meta || {},
    alias: savers5DOxZsJAJ9Meta?.alias || [],
    redirect: savers5DOxZsJAJ9Meta?.redirect,
    component: () => import("/vercel/path0/pages/savers.vue").then(m => m.default || m)
  },
  {
    name: stats9JkEnhPwt4Meta?.name ?? "stats",
    path: stats9JkEnhPwt4Meta?.path ?? "/stats",
    meta: stats9JkEnhPwt4Meta || {},
    alias: stats9JkEnhPwt4Meta?.alias || [],
    redirect: stats9JkEnhPwt4Meta?.redirect,
    component: () => import("/vercel/path0/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: statusTkkg17h3mLMeta?.name ?? "status",
    path: statusTkkg17h3mLMeta?.path ?? "/status",
    meta: statusTkkg17h3mLMeta || {},
    alias: statusTkkg17h3mLMeta?.alias || [],
    redirect: statusTkkg17h3mLMeta?.redirect,
    component: () => import("/vercel/path0/pages/status.vue").then(m => m.default || m)
  },
  {
    name: swapwGvG0t6ytpMeta?.name ?? "swap",
    path: swapwGvG0t6ytpMeta?.path ?? "/swap",
    meta: swapwGvG0t6ytpMeta || {},
    alias: swapwGvG0t6ytpMeta?.alias || [],
    redirect: swapwGvG0t6ytpMeta?.redirect,
    component: () => import("/vercel/path0/pages/swap.vue").then(m => m.default || m)
  },
  {
    name: walletNmivtXEuWQMeta?.name ?? "wallet",
    path: walletNmivtXEuWQMeta?.path ?? "/wallet",
    meta: walletNmivtXEuWQMeta || {},
    alias: walletNmivtXEuWQMeta?.alias || [],
    redirect: walletNmivtXEuWQMeta?.redirect,
    component: () => import("/vercel/path0/pages/wallet.vue").then(m => m.default || m)
  },
  {
    name: withdrawgYZU9k5xLoMeta?.name ?? "withdraw",
    path: withdrawgYZU9k5xLoMeta?.path ?? "/withdraw",
    meta: withdrawgYZU9k5xLoMeta || {},
    alias: withdrawgYZU9k5xLoMeta?.alias || [],
    redirect: withdrawgYZU9k5xLoMeta?.redirect,
    component: () => import("/vercel/path0/pages/withdraw.vue").then(m => m.default || m)
  }
]