export const useSwapStore = defineStore('swap', {
  state: () => ({
    gettingTransactionFee: true,
    checkingTokenApproval: false,
    needsTokenApproval: false,
    tokenAllowance: 0,
    isApprovingToken: false,
    approveEstimatedFee: 0,
    approveTokenStatus: 'Confirm',
    approveTokenIntegration: '',
  }),
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSwapStore, import.meta.hot))
}
